import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

/**
 * This service allows components to perform an action after a route changed away from a defined route.
 *
 * Example use case: Reset the search-string of the federations table as soon as the user navigates away from the /federation-pages
 */
@Injectable({ providedIn: 'root' })
export class RouteResetService {
  private destroyRef = inject(DestroyRef);
  private registeredRouteResets: { [route: string]: () => void };
  private lastVisitedRoute: string;

  constructor(private router: Router) {}

  public registerRouteReset(route: string, resetFn: () => void): void {
    if (!this.registeredRouteResets) {
      this.registeredRouteResets = {};
      this.listenToRouteChanges();
    }

    if (!this.registeredRouteResets[route]) {
      this.registeredRouteResets[route] = resetFn;
    }
  }

  private listenToRouteChanges(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        // Important: We need the destroyRef here, otherwise it seems to immediately unsubscribe if used outside a constructor
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(event => {
        const baseRoute = RouteResetService.getBaseRouteFromUrl((event as NavigationEnd).url);
        // Angular router only tells us about a route change, but not what the route was before. So we have to keep track of that ourselves
        if (!this.lastVisitedRoute) {
          this.lastVisitedRoute = baseRoute;
        }
        if (this.lastVisitedRoute !== baseRoute && this.registeredRouteResets[baseRoute]) {
          this.registeredRouteResets[baseRoute]();
        }
        this.lastVisitedRoute = baseRoute;
      });
  }

  private static getBaseRouteFromUrl(url: string): string {
    return url.split('/').filter(split => !!split)[0];
  }
}
