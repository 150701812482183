import { NativeDateAdapter } from '@angular/material/core';

import { DateUtil } from '@celum/ng2base';

export class CustomDateAdapter extends NativeDateAdapter {
  public override format(date: Date, displayFormat: { year?: string; month?: string; day?: string }): string {
    // Customize formatting in a full numeric date.
    if (displayFormat.day === 'numeric' && displayFormat.month === 'numeric' && displayFormat.year === 'numeric') {
      return DateUtil.getFormattedDateNumeric(date.getTime(), 'de');
    }

    // For the other cases, return the default
    return super.format(date, displayFormat);
  }

  // For returning Monday as the fist day of the week
  public override getFirstDayOfWeek(): number {
    return 1;
  }
}
