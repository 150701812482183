import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InvitationStatus } from '@celum/authentication';
import { Account, AccountFilters, BatchDTO, BatchParams, getPaginationParams, WorkRoomsPlanType } from '@celum/sacc/domain';

import { RestService } from './rest.service';
import { Utils } from '../utils';

/**
 * @deprecated
 * This service is deprecated and will be removed in the future. Use the new AccountService instead.
 */
@Injectable({ providedIn: 'root' })
export class LegacyAccountService extends RestService {
  private accountRecordHeight = 48;
  private readonly batchSize = Utils.calculateBatchSize(this.accountRecordHeight);

  constructor(private httpClient: HttpClient) {
    super();
  }

  public invitationExists(accountId: string, email: string): Observable<boolean> {
    return this.httpClient
      .post<any>(this.getApiUrl(`/accounts/${accountId}/members/exists`), {
        nameOrEmailContains: email,
        invitationStatusIn: [InvitationStatus.ACCEPTED, InvitationStatus.APPROVED, InvitationStatus.INVITED]
      })
      .pipe(map(r => r.exists));
  }

  // TODO: remove this once all the functionality is moved from the store to the new account service
  public get(id: string): Observable<Account> {
    return this.httpClient.get<Account>(this.getApiUrl(`/accounts/${id}`)).pipe(
      map(account => {
        account.licenses = [...account.licenses].sort((a, b) => a.type.localeCompare(b.type));
        return account;
      })
    );
  }

  public update(account: Account): Observable<Account> {
    return this.httpClient.patch<Account>(this.getApiUrl(`/accounts/${account.id}`), account);
  }

  public updateDetails(account: Account): Observable<Account> {
    return this.httpClient.patch<Account>(this.getApiUrl(`/accounts/${account.id}/companyDetails`), {
      ...account.companyDetails
    });
  }

  public create(account: Account): Observable<Account> {
    return this.httpClient.post<Account>(this.getApiUrl('/accounts'), account);
  }

  public fetchBatch(batchParams: BatchParams = {}): Observable<BatchDTO<Account>> {
    return this.httpClient.get<BatchDTO<Account>>(this.getApiUrl('/accounts'), {
      params: getPaginationParams({
        ...batchParams,
        count: this.batchSize
      })
    });
  }

  public search(filter: AccountFilters, batchParams: BatchParams = {}): Observable<BatchDTO<Account>> {
    const activeLicenses = filter.activeLicenses?.length > 0 ? filter.activeLicenses : undefined;
    const body = {
      ...(filter.orgName && { name: filter.orgName }),
      ...(filter.orgId && { id: filter.orgId }),
      ...(filter.owner && { ownerNameOrEmail: filter.owner }),
      ...(activeLicenses && { activeLicenses })
    } as any;

    if (filter.createdOnFrom && filter.createdOnTo) {
      body.createdOn = {
        from: `${filter.createdOnFrom.getFullYear()}-${(filter.createdOnFrom.getMonth() + 1).toString().padStart(2, '0')}-${filter.createdOnFrom.getDate().toString().padStart(2, '0')}T00:00:00.000Z`,
        to: `${filter.createdOnTo.getFullYear()}-${(filter.createdOnTo.getMonth() + 1).toString().padStart(2, '0')}-${filter.createdOnTo.getDate().toString().padStart(2, '0')}T23:59:59.999Z`
      };
    }

    return this.httpClient.post<BatchDTO<Account>>(this.getApiUrl(`/accounts/search`), body, {
      params: getPaginationParams({
        ...batchParams,
        count: this.batchSize
      })
    });
  }

  public requestNewPlanForWorkRooms(accountId: string, plan: WorkRoomsPlanType): Observable<void> {
    return this.httpClient.post<void>(this.getApiUrl(`/accounts/${accountId}/marketing/workrooms/plans`), { selectedPlan: plan });
  }

  public requestContentHubDemo(accountId: string): Observable<void> {
    return this.httpClient.post<void>(this.getApiUrl(`/accounts/${accountId}/marketing/contenthub/demo`), {});
  }
}
