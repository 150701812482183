import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, race } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { accountActions } from '../store/account/account.action';
import { AppState } from '../store/app.state';
import { notificationActions } from '../store/notification/notification.action';

@Injectable()
export class AccountResolver implements Resolve<Action> {
  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private translateService: TranslateService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<Action> {
    const accountId = route.paramMap.get('id');

    const success = this.actions$.pipe(ofType(accountActions.getOneSuccess));
    const failure = this.actions$.pipe(
      ofType(accountActions.getOneFailure),
      tap(() => {
        this.store$.dispatch(
          notificationActions.error({
            message: this.translateService.instant('SERVICES.ACCOUNT.RESOLVER.FETCH_ACCOUNT_DETAILS_FAILURE')
          })
        );
        window.history.back();
      })
    );

    this.store$.dispatch(accountActions.getOne({ id: accountId }));

    return race(success, failure).pipe(take(1));
  }
}
