<div class="sacc-search-and-select">
  <mat-form-field appearance="outline" class="sacc-search-and-select_search-input">
    <input #autocomplete matInput [formControl]="elementControl" [matAutocomplete]="auto" [placeholder]="placeHolder | translate" />
    @if (!elementControl.value) {
      <celum-icon class="sacc-search-and-select_search-input-icon" matSuffix [configuration]="icons.arrowDown"></celum-icon>
    } @else {
      <celum-icon class="sacc-search-and-select_search-input-icon" matSuffix [configuration]="icons.clear" (click)="clearInput()"></celum-icon>
    }
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete" class="sacc-search-and-select_autocomplete" (opened)="sortAndFilter()">
    @for (element of filteredElements; track trackByFn($index, element)) {
      <mat-option class="sacc-search-and-select_autocomplete-option">
        <div class="sacc-search-and-select_autocomplete-option-wrapper" (click)="optionClicked($event, element)">
          <div class="sacc-search-and-select_autocomplete-option-wrapper-content">
            <span class="sacc-search-and-select_autocomplete-option-repository-id">{{ nameMapping(element) }}</span>
            <mat-checkbox
              class="sacc-search-and-select_autocomplete-option-content-check"
              [checked]="isSelected(element)"
              (change)="toggleSelection(element)"
              (click)="$event.stopPropagation()"></mat-checkbox>
          </div>
        </div>
      </mat-option>
    }
    @if (elementsLoading === false && filteredElements.length <= 0) {
      <mat-option class="sacc-search-and-select_autocomplete-not-found" disabled="true">
        <div class="sacc-search-and-select_autocomplete-not-found_image">
          <img alt="{{ 'COMPONENTS.SEARCH_AND_SELECT.NOT_FOUND' | translate }}" class="not-found-img" src="assets/images/no-entry-found.svg" />
          <div>{{ 'COMPONENTS.SEARCH_AND_SELECT.NOT_FOUND' | translate }}</div>
        </div>
      </mat-option>
    }
    @if (elementsLoading === false && allElementsLoaded === false && filteredElements.length > 0) {
      <mat-option class="sacc-search-and-select_autocomplete-further-results" disabled="true">
        {{ 'COMPONENTS.SEARCH_AND_SELECT.FURTHER_RESULTS' | translate }}
      </mat-option>
    }
  </mat-autocomplete>

  @if (showSelectedElements && internalSelectedElements.length > 0) {
    <celum-chip-set class="sacc-search-and-select_chips" [showClearAll]="false">
      @for (element of internalSelectedElements; track trackByFn($index, element)) {
        <celum-chip [text]="nameMapping(element)" (remove)="remove(element)"></celum-chip>
      }
    </celum-chip-set>
  }
</div>
