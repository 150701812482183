<div class="sacc-form-field">
  <mat-label [attr.required]="required || null">{{ label | translate }}</mat-label>
  <mat-form-field>
    <input
      #emailInput
      class="celum-input"
      [attr.required]="required || null"
      [formControl]="email"
      [matAutocomplete]="auto"
      matInput
      pattern="^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$"
      placeholder="{{ emailPlaceHolder }}"
      type="email"
      (keyup)="emailTextChanged.next($event.target)" />
    @if (email.valid) {
      <mat-hint>{{ emailHint }}</mat-hint>
    } @else if (email.hasError('email')) {
      <mat-error>{{ 'COMPONENTS.EMAIL_INPUT.INVALID_CHARACTERS' | translate }}</mat-error>
    }
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete">
    @if (autocompleteCfg.email && showEmail(autocompleteCfg.isNew, autocompleteCfg.email)) {
      <mat-option [value]="autocompleteCfg.email" (click)="addEmail()">
        <div class="email-option">
          <span class="new">{{ emailStatusIndicator }}</span>
          <celum-icon [configuration]="emailIcon"></celum-icon>
          {{ autocompleteCfg.email }}
        </div>
      </mat-option>
    }
    @if (showValidationWarning(autocompleteCfg.isNew, autocompleteCfg.email)) {
      <mat-option disabled="true" [value]="autocompleteCfg.email">
        <em>{{ validationWarningMessage }}</em>
      </mat-option>
    }
  </mat-autocomplete>
</div>
