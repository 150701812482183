import { ModuleWithProviders, NgModule } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

import { CustomDateAdapter } from '../custom-date-adapter';

@NgModule({})
export class DateModule {
  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale(this.translateService.getDefaultLang());

    this.translateService.onLangChange.subscribe(event => {
      this.dateAdapter.setLocale(event.lang);
    });
  }

  public static forRoot(): ModuleWithProviders<DateModule> {
    return {
      ngModule: DateModule,
      providers: [
        {
          provide: DateAdapter,
          useClass: CustomDateAdapter
        }
      ]
    };
  }
}
