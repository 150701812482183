import { Sort } from '@angular/material/sort';

import { PaginationOption } from '@celum/shared/domain';

export enum AccountFilterLicenseType {
  WORK = 'WORK',
  EXPERIENCE = 'EXPERIENCE',
  DRIVE = 'DRIVE',
  CONTENT = 'CONTENT_HUB'
}

export enum AccountFilterCategory {
  ActiveLicenses
}

export interface AccountFilters extends PaginationOption {
  orgId?: string;
  orgName?: string;
  owner?: string;
  createdOnFrom?: Date;
  createdOnTo?: Date;
  activeLicenses?: AccountFilterLicenseType[];

  sort: Sort;
  continuationToken?: string;
}

export function createEmptyAccountFilter(sort?: Sort): AccountFilters {
  return {
    orgId: '',
    orgName: '',
    owner: '',
    createdOnFrom: undefined,
    createdOnTo: undefined,
    sort: sort || createDefaultAccountFilterSort()
  };
}

export function createDefaultAccountFilterSort(): Sort {
  return { active: 'createdOn', direction: 'asc' };
}

export type AccountFilterItem = { title: string; typeTitle: string } & { type: AccountFilterCategory.ActiveLicenses; value: AccountFilterLicenseType };

export const filterItemByValue: Record<AccountFilterLicenseType, AccountFilterItem> = {
  [AccountFilterLicenseType.WORK]: {
    title: 'COMPONENTS.TABLE.HEADER.FILTERS.SEARCH.TYPES.SUBSCRIPTIONS.SUBSCRIPTION_TYPES.WORK',
    typeTitle: 'COMPONENTS.TABLE.HEADER.FILTERS.SEARCH.TYPES.SUBSCRIPTIONS.TITLE',
    type: AccountFilterCategory.ActiveLicenses,
    value: AccountFilterLicenseType.WORK
  },
  [AccountFilterLicenseType.EXPERIENCE]: {
    title: 'COMPONENTS.TABLE.HEADER.FILTERS.SEARCH.TYPES.SUBSCRIPTIONS.SUBSCRIPTION_TYPES.EXPERIENCE',
    typeTitle: 'COMPONENTS.TABLE.HEADER.FILTERS.SEARCH.TYPES.SUBSCRIPTIONS.TITLE',
    type: AccountFilterCategory.ActiveLicenses,
    value: AccountFilterLicenseType.EXPERIENCE
  },
  [AccountFilterLicenseType.DRIVE]: {
    title: 'COMPONENTS.TABLE.HEADER.FILTERS.SEARCH.TYPES.SUBSCRIPTIONS.SUBSCRIPTION_TYPES.DRIVE',
    typeTitle: 'COMPONENTS.TABLE.HEADER.FILTERS.SEARCH.TYPES.SUBSCRIPTIONS.TITLE',
    type: AccountFilterCategory.ActiveLicenses,
    value: AccountFilterLicenseType.DRIVE
  },
  [AccountFilterLicenseType.CONTENT]: {
    title: 'COMPONENTS.TABLE.HEADER.FILTERS.SEARCH.TYPES.SUBSCRIPTIONS.SUBSCRIPTION_TYPES.CONTENT_HUB',
    typeTitle: 'COMPONENTS.TABLE.HEADER.FILTERS.SEARCH.TYPES.SUBSCRIPTIONS.TITLE',
    type: AccountFilterCategory.ActiveLicenses,
    value: AccountFilterLicenseType.CONTENT
  }
};
