import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Account, AccountFilters, createEmptyAccountFilter } from '@celum/sacc/domain';

export interface AccountState extends EntityState<Account> {
  continuationToken: string;
  loading: boolean;
  selectedAccountId: string;
  totalCount: number;
  filterCount: number;
  filter: AccountFilters;
}

export const accountAdapter: EntityAdapter<Account> = createEntityAdapter<Account>();

export const initialAccountState: AccountState = accountAdapter.getInitialState({
  loading: false,
  continuationToken: '',
  selectedAccountId: '',
  totalCount: 0,
  filterCount: 0,
  filter: createEmptyAccountFilter()
});
